import React from "react"
import { graphql ,Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import Img from "gatsby-image"

const Container = styled.div`
display: flex;
flex-wrap: nowrap;
flex-direction: row;
`;


const IndexPage = ({data}) => (
  <Layout>
    <SEO title="Home" />
    <Container>
    <div>
    <h3>This is</h3>
    <h1>
        Charlene Liu
      </h1>
    <h3>I'm a polyglot Software Engineer livig on the cloud, <br></br></h3>
    <h3>passionate about working on various technologies with my creativity to solve problems.</h3>
    <h3>I Love to collaborate with other people and share experience.</h3>
    <h3>Please don't hestitate to drop me a message if you wanna say hi.</h3>
    <h3>Hope you enjoy this little place!</h3>
    </div>
    <Link
     to="/about/"
     style={{ width: `400px`,height: `400px`, marginTop:`2rem`, marginBottom: `1.45rem`}}>
      <Img fluid={data.me.childImageSharp.fluid} alt="living on the cloud"/>
    </Link>
    </Container>
  </Layout>
)

export default IndexPage

export const fluidImage = graphql`
fragment fluidImage on File {
  childImageSharp {
    fluid(maxWidth: 400) {
      ...GatsbyImageSharpFluid
    }
  }
}
`;

export const pageQuery = graphql`
  query {
    me: file(relativePath: { eq: "me.png" }) {
      ...fluidImage
    }
  }
`